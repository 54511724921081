.Modal--default {
    @apply absolute z-40 bg-od-black-500;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 538px;
}

.Modal--sidebar {
    @apply absolute z-40 bg-od-black-500 h-screen;
    top: 0;
    right: 0;
    width: 600px;
}

.Modal__content-wrapper {
    @apply w-full h-full bg-od-black-500 p-8;
}

.Modal__overlay {
    @apply fixed w-full h-full top-0 left-0 right-0 left-0 z-30;
    /* Modal inherits overlay style so we need to use rgba here https://github.com/reactjs/react-modal/issues/501 */
    background-color: rgba(3, 3, 3, 0.8);
    transition: all 100ms ease-in-out;
    opacity: 0;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}
