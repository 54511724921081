@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply h-full;
}

.od-font-family {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.OpforApp {
  @apply m-0 text-od-white-900 bg-od-black-900 od-font-family;
}

code, .code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* TODO: Extract into a standalone component */

.od-sidebar-li--active {
  background: rgba(21, 25, 31, 0.05);
  box-shadow: inset 0 0 68px rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
}

.od-sidebar-li--active + .od-sidebar-li__box--active {
  display: block;
}

.od-element--highlighted {
  border: 1px solid rgba(254, 129, 0, 0.6);
}

.od-scrollbar::-webkit-scrollbar {
  @apply w-0.5 h-1 bg-transparent rounded;
}

.od-scrollbar::-webkit-scrollbar-thumb {
  @apply w-0.5 h-4 bg-od-black-100 rounded;
}

.od-progress {
  background: conic-gradient(from 131.63deg at 50% 50%, #9A92FF 0deg, rgba(116, 94, 255, 0) 360deg);
}

.OpforApp--email-template * {
  all: initial;
}
