h1, .h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0;
}

h2, .h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

h3, .h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
}

h4, .h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.03em;
}

.od-font-regular {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    font-style: normal;
    font-weight: normal;
}

.od-font-headline-regular {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0;
}

.od-font-title-semi-bold {
    line-height: 32px;
    font-size: 16px;
    font-weight: 600;
}

.od-font-title-regular {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
}

.od-font-title-medium {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: center;

}

.od-font-main-medium {
    line-height: 20px;
    font-size: 14px;
}

.od-font-main-regular {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.01em;
}

.od-font-main-semi-bold {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
}

.od-font-secondary-medium {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
}

.od-font-secondary-semi-bold {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.03em;
}

.od-font-secondary-regular {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
}

.od-font-button {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.od-font-third-regular {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 160% */

    letter-spacing: -0.02em;
}

.od-font-third-semi-bold {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.03em;
}

table tr td {
    @apply od-font-main-medium !important;
}

.link-text {
    @apply text-od-white-900 hover:text-od-button-primary-hover;
}

.link--text-highlighted {
    @apply text-od-button-primary-focus hover:text-od-button-primary-hover;
}
