/* https://codepen.io/geoffgraham/pen/jrWwWM */
/* The typing effect */
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #FE8100 }
}

.od-typewriter {
    @apply overflow-hidden whitespace-nowrap px-1 my-0 mx-auto;
    border-right: .15em solid #FE8100;
    animation:
            typing 2s steps(25, end),
            blink-caret .5s step-end infinite;
}
