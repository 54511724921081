table {
    display: grid;
    border-collapse: collapse;
    align-items: center;
}

tbody, thead, tr {
    @apply contents;
}

table td {
    @apply bg-od-black-700 flex items-center py-4 h-full;
}

table th {
    @apply py-4 h-4 mb-7;
}

table td:first-child, table th:first-child {
    @apply pl-5;
}

table td:last-child, th:last-child {
    @apply pr-5;
}

tr.od-element--highlighted td {
    border-top: 1px solid rgba(254, 129, 0, 0.6);
    border-bottom: 1px solid rgba(254, 129, 0, 0.6);
}

tr.od-element--highlighted td:first-child {
    border-left: 1px solid rgba(254, 129, 0, 0.6);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

tr.od-element--highlighted td:last-child {
    border-right: 1px solid rgba(254, 129, 0, 0.6);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
