.Select .Select__control {
    @apply w-full text-od-white-900 bg-od-black-700 placeholder-od-white-500 rounded-od-10 focus:outline-none px-3 cursor-pointer;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.09);
    border: 1px solid rgba(63, 64, 68, 0.4);
    min-height: 44px;
}

.Select .Select__control--is-focused,
.Select .Select__control:hover {
    @apply outline-none border border-od-white-300;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.09);
}

.Select .Select__single-value {
    @apply text-od-white-900 text-sm;
}
.Select .Select__single-value--is-disabled {
    @apply text-od-white-500;
}

.Select .Select__value-container--is-multi {
    @apply flex p-0 gap-1 items-center;
}
.Select .Select__value-container--is-multi.Select__value-container--has-value {
    @apply -ml-2;
}

.Select .Select__indicators {
    @apply flex gap-2 items-center;
}

.Select .Select__multi-value {
    @apply h-9 px-4 py-2 bg-od-black-100 rounded-od-10 text-od-white-900 flex items-center gap-2 border-0 m-0;
}

.Select .Select__multi-value .Select__multi-value__label {
    @apply text-od-white-900;
}

.Select .Select__multi-value .Select__multi-value__remove:hover {
    @apply bg-transparent;
}

.Select:not(.Select--has-separator) .Select__indicator-separator {
    @apply hidden;
}

.Select .Select__dropdown-indicator {
    @apply cursor-pointer;
}

.Select .Select__control--menu-is-open .Select__dropdown-indicator {
    @apply transform rotate-180;
}

.Select .Select__menu {
    @apply bg-od-black-300 opacity-100 py-5 px-3 mb-0 mt-1;
    z-index: 100;
}

.Select .Select__menu-list::-webkit-scrollbar {
    @apply w-0.5 bg-transparent;
}

.Select .Select__menu-list::-webkit-scrollbar-thumb {
    @apply w-0.5 h-4 bg-od-black-100 rounded-od-1;
}
