.react-tiny-popover-container {
    z-index: 50;
}

.od-tooltip {
    @apply bg-od-black-100 shadow-od-toast rounded-xl;
}

.od-tooltip--md {
    @apply px-4 py-3;
}

.od-tooltip--sm {
    @apply px-3 py-2 text-sm;
}
