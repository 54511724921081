.Button:not(.Button--text) {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.09);
}

.Button--primary {
    background: #594CEA;
}

.Button--primary:hover {
    background: #675BEF;
}

.Button--primary:focus {
    background: #4236CF;
}

.Button--secondary {
    background: rgba(152, 152, 152, 0.2);
    border: 1px solid rgba(152, 152, 152, 0.2);
}

.Button--secondary:hover {
    background: rgba(166, 166, 166, 0.2);
    border: 1px solid rgba(166, 166, 166, 0.2);
}

.Button--secondary:focus {
    background: rgba(90, 90, 90, 0.2);
    border: 1px solid rgba(90, 90, 90, 0.2);
}

.Button--dangerous {
    border: 1px solid rgba(234, 71, 96, 0.2);
    background: rgba(234, 71, 96, 0.2);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.09);
}
