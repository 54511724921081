/* Change the white to any color https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #0F1015 inset !important;
    -webkit-text-fill-color: #ffffff !important;
}

.Input__input--transparent:-webkit-autofill,
.Input__input--transparent:-webkit-autofill:hover,
.Input__input--transparent:-webkit-autofill:focus,
.Input__input--transparent:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #030303 inset !important;
    border: none !important;
}

.Input__control {
    @apply h-11 w-full text-od-white-900 bg-od-black-700 placeholder-od-white-500 rounded-od-10 focus:outline-none px-3;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.09);
    border: 1px solid rgba(63, 64, 68, 0.4);
}

.Input--billing {
    @apply focus:outline-none w-full py-1 h-11 w-full placeholder-od-white-500 focus:outline-none rounded-od-10 border bg-od-black-700 px-3 text-od-white-900 border-od-black-100;
}

.Input__label--billing {
    @apply text-xs text-od-white-300 mb-1;
}

/* TIME Picker */
.ant-picker-input input {
    @apply Input__control;
}

.checkbox {
    @apply appearance-none focus:appearance-none h-4 w-4 rounded-sm border border-od-action bg-od-white-900 focus:outline-none transition duration-200 cursor-pointer focus:ring-0;
}

.Input--chargebee {
    @apply h-11 w-full placeholder-od-white-500 focus:outline-none rounded-od-10 px-3 border border-od-black-100 bg-od-black-700 grid items-center;
}
